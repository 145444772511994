import axios from 'axios';
import ServiceSetting from '../settings/ServiceSetting'
import {AccessStorage} from "../storages/AccessStorage";

export class EventService {
  static get = async () => {
    try {
      let access = await AccessStorage.get();
      let currentUser = await AccessStorage.getUser();
      let resp = await axios.get(
        `${ServiceSetting.adminUrl}v1/event/${currentUser.guid}`,
        {headers: {"Authorization": `${access['token_type']} ${access['access_token']}`}}
      );
      return resp.data;
    } catch (e) {
      return null;
    }
  };

  static delete = async (eventId) => {
    try {
      let access = AccessStorage.get();
      let resp = await axios.delete(
        `${ServiceSetting.adminUrl}v1/event/${eventId}`,
        {headers: {"Authorization": `${access['token_type']} ${access['access_token']}`}}
      );
      return resp.data;
    } catch (e) {
      return null;
    }
  };

  static post = async (event) => {
    try {
      let access = AccessStorage.get();
      let currentUser = await AccessStorage.getUser();
      let resp = await axios.post(
        `${ServiceSetting.adminUrl}v1/event/${currentUser.type.guid}`, event,
        {headers: {"Authorization": `${access['token_type']} ${access['access_token']}`}}
      );
      return resp.data;
    } catch (e) {
      return null;
    }
  };

  static put = async (event) => {
    try {
      let access = AccessStorage.get();
      let resp = await axios.put(
        ServiceSetting.adminUrl + 'v1/event', event,
        {headers: {"Authorization": `${access['token_type']} ${access['access_token']}`}}
      );
      return resp.data;
    } catch (e) {
      return null;
    }
  };

  static getCandidatesByAddonId = async (addonId) => {
    try {
      let access = AccessStorage.get();
      let resp = await axios.get(
        ServiceSetting.adminUrl + 'v1/event/candidate/' + addonId,
        {headers: {"Authorization": `${access['token_type']} ${access['access_token']}`}}
      );
      return resp.data;
    } catch (e) {
      return null;
    }
  };

  static getAddonByGuid = async (addonGuid) => {
    try {
      let access = AccessStorage.get();
      let resp = await axios.get(
        ServiceSetting.adminUrl + 'v1/event/addon/' + addonGuid,
        {headers: {"Authorization": `${access['token_type']} ${access['access_token']}`}}
      );
      return resp.data;
    } catch (e) {
      return null;
    }
  };

  static generatePdfs = async (criteria) => {
    try {
      let access = AccessStorage.get();
      let resp = await axios.post(
        ServiceSetting.adminUrl + 'v1/assessment/generatepdf/' + criteria.addonGuid, criteria.candidateIdList,
        {headers: {"Authorization": `${access['token_type']} ${access['access_token']}`}}
      );
      return resp.data;
    } catch (e) {
      return null;
    }
  };

  static sendPdfEmails = async (criteria) => {
    try {
      let access = AccessStorage.get();
      let resp = await axios.post(
        ServiceSetting.adminUrl + 'v1/assessment/sendresult/' + criteria.addonGuid, criteria.candidateIdList,
        {headers: {"Authorization": `${access['token_type']} ${access['access_token']}`}}
      );
      return resp.data;
    } catch (e) {
      return null;
    }
  };

}
