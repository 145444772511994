export class AccessStorage {
  static set(data) {
    localStorage.setItem('access', JSON.stringify(data));
  }

  static get() {
    let data = localStorage.getItem('access');
    return JSON.parse(data);
  }

  static setUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  static getUser() {
    let data = localStorage.getItem('user');
    return JSON.parse(data);
  }

  static clear() {
    localStorage.clear();
  }
}
