// @material-ui/icons
import Person from "@material-ui/icons/Person";
// core components/views for Admin layout
import {Users} from "./views/users/Users";
import {Events} from "./views/events/Events";
import {Candidates} from "./views/candidates/Candidates";

const dashboardRoutes = [
  {
    path: "/users",
    name: "Users",
    icon: Person,
    component: Users,
    layout: "/admin"
  },
  {
    path: "/events",
    name: "Events",
    icon: "calendar_today",
    component: Events,
    layout: "/admin"
  },
  {
    path: "/candidates",
    name: "Candidates",
    icon: "supervisor_account",
    component: Candidates,
    layout: "/admin",
    params: '/:addonGuid',
    hide: true
  }
];

export default dashboardRoutes;
