import React, {Component} from 'react';
import TextField from "@material-ui/core/TextField";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import ThomasLogo from "../../assets/img/thomas_logo_no_bg.svg";
import {Spacer} from "../../components/Spacer/Spacer";
import {Redirect} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import {AuthService} from "../../services/AuthService";
import {UsersService} from "../../services/UsersService";
import {Loading} from "../../components/Loading/Loading";
import {AccessStorage} from "../../storages/AccessStorage";

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      isLogged: false,
      loading: false
    };
  }

  render() {
    return (
      <Container component="main"
                 maxWidth="xs">
        {this.renderRedirect()}
        <CssBaseline/>
        <div style={styles.paper}>
          <img src={ThomasLogo}
               width={"250px"}
               alt={"Thomas"}/>
          <Spacer/>
          <form style={styles.form}
                noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              value={this.state.username}
              onChange={this.handleUsernameChange}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={this.state.password}
              onChange={this.handlePasswordChange}
              autoComplete="current-password"/>
            <Spacer/>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={this.onClickLogin}>
              Sign In
            </Button>
          </form>
        </div>
        <ToastContainer/>
        <Loading hide={this.state.loading}/>
      </Container>
    )
  }

  handleUsernameChange = async (event) => {
    this.setState({username: event.target.value});
  };

  handlePasswordChange = async (event) => {
    this.setState({password: event.target.value});
  };

  onClickLogin = async (event) => {
    event.preventDefault();
    this.setState({loading: true});
    let access = await AuthService.login(this.state.username, this.state.password);
    if (access) {
      let user = await UsersService.getByUsernameAndPassword(this.state.username, this.state.password);
      await AccessStorage.setUser(user);
      this.setState({isLogged: true});
    } else {
      this.toastError('Email or password invalid');
    }
    this.setState({loading: false});
  };

  renderRedirect = () => {
    if (this.state.isLogged) {
      return <Redirect to='/admin/users'/>
    }
  };

  toastError = (msg) => {
    toast.error(msg, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true
    });
  }
}

const styles = {
  paper: {
    marginTop: 80,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: 10,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  }
};


