import React, {Component} from "react";
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Card from "../../components/Card/Card.jsx";
import CardHeader from "../../components/Card/CardHeader.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import MaterialTable from "material-table";
import {UsersService} from "../../services/UsersService";
import {Loading} from "../../components/Loading/Loading";
import Container from "@material-ui/core/Container";

export class Users extends Component {

  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {title: 'Name', field: 'name'},
        {title: 'Email', field: 'email'},
        {title: 'Password', field: 'password'}
      ],
      data: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.updateUsers().then(() => {
      this.setState({loading: false});
    });
  }

  render() {
    return (
      <GridContainer>
        <Loading hide={this.state.loading}/>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 style={styles.cardTitle}>User list</h4>
            </CardHeader>
            <CardBody>
              <MaterialTable
                style={{boxShadow: 'none'}}
                options={{
                  sorting: false,
                  showTitle: false,
                  draggable: false,
                  actionsColumnIndex: 4
                }}
                columns={this.state.columns}
                data={this.state.data}
                editable={{
                  onRowAdd: newData =>
                    new Promise((resolve, reject) =>
                      setTimeout(() => {
                        UsersService.post(newData).then(() => {
                          this.updateUsers().then(resolve);
                        });
                      }, 1000)
                    ),
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) =>
                      setTimeout(() => {
                        UsersService.put(newData).then(() => {
                          this.updateUsers().then(resolve);
                        });
                      }, 1000)
                    ),
                  onRowDelete: oldData =>
                    new Promise((resolve, reject) =>
                      setTimeout(() => {
                        let data = this.state.data;
                        const index = data.indexOf(oldData);
                        let adminUserId = data[index]['adminUserId'];
                        UsersService.delete(adminUserId).then(() => {
                          this.updateUsers().then(resolve);
                        });
                      }, 1000)
                    ),
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  async updateUsers() {
    let users = await UsersService.get();
    this.setState({data: users});
  }
}

export const styles = {
  cardTitle: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};
