import React from "react";
import ReactDOM from "react-dom";
import {createBrowserHistory} from "history";
import {Router, Route, Switch, Redirect} from "react-router-dom";
import "assets/css/material-dashboard-react.css?v=1.7.0";
import 'react-toastify/dist/ReactToastify.css';
import Admin from "./layouts/Admin";
import {Login} from "./views/login/Login";

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/login" component={Login}/>
      <Route path="/admin" component={Admin}/>
      <Redirect from="/" to="/login"/>
    </Switch>
  </Router>,
  document.getElementById("root")
);
