import React, {Component} from "react";
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Card from "../../components/Card/Card.jsx";
import CardHeader from "../../components/Card/CardHeader.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import MaterialTable from "material-table";
import {EventService} from "../../services/EventsService";
import {Loading} from "../../components/Loading/Loading";
import {Redirect} from "react-router-dom";

export class Events extends Component {

  constructor(props) {
    super(props);
    this.state = {
      addonGuidSelected: null,
      columns: [
        {title: 'Name', field: 'name'},
        {title: 'Code', field: 'code'},
        {title: 'Password', field: 'password'},
        {
          title: 'Assessment', field: 'assessmentPackageId', lookup: {
            1001: "PPA",
            1002: "PPA_Plus",
            // 1003: "GIA",
            // 1004: "TEIQue",
            // 1005: "PPA_GIA",
            // 1006: "PPA_TEIQue",
            // 1007: "PPA_GIA_TEIQue",
            // 1008: "PPA_Plus_GIA",
            // 1009: "PPA_Plus_TEIQue",
            // 1010: "PPA_Plus_GIA_TEIQue",
            // 1011: "GIA_TEIQue",
            // 1012: "HPTi",
            // 1013: "PPA_HPTi",
            // 1014: "PPA_Plus_HPTi",
            // 1015: "GIA_HPTi",
            // 1016: "TEIQue_HPTi",
            // 1017: "PPA_GIA_HPTi",
            // 1018: "PPA_TEIQue_HPTi",
            // 1019: "PPA_GIA_TEIQue_HPTi",
            // 1020: "PPA_Plus_GIA_HPTi",
            // 1021: "PPA_Plus_TEIQue_HPTi",
            // 1022: "PPA_Plus_GIA_TEIQue_HPTi",
            // 1023: "GIA_TEIQue_HPTi"
          }
        },
        {
          title: 'Start',
          field: 'start',
          type: 'date',
          render: rowData => <span>{Events.formatDate(rowData.start)}</span>
        },
        {
          title: 'End',
          field: 'end',
          type: 'date',
          render: rowData => <span>{Events.formatDate(rowData.end)}</span>
        },
      ],
      data: [],
      loading: true,
      goToCandidates: false
    };
  }

  componentDidMount() {
    this.updateEvents().then(() => {
      this.setState({loading: false});
    });
  }


  renderRedirect = () => {
    if (this.state.goToCandidates) {
      return <Redirect to={'/admin/candidates/' + this.state.addonGuidSelected}/>
    }
  };

  render() {
    return (
      <>
        {this.renderRedirect()}
        <GridContainer>
          <Loading hide={this.state.loading}/>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 style={styles.cardTitle}>Event list</h4>
              </CardHeader>
              <CardBody>
                <MaterialTable
                  style={{boxShadow: 'none'}}
                  options={{
                    sorting: false,
                    showTitle: false,
                    draggable: false,
                    actionsColumnIndex: 6
                  }}
                  columns={this.state.columns}
                  data={this.state.data}
                  actions={[
                    {
                      icon: 'supervisor_account',
                      tooltip: 'Candidates',
                      onClick: (event, rowData) => {
                        this.setState({addonGuidSelected: rowData.addonGuid});
                        this.setState({goToCandidates: true});
                      }
                    }]}
                  editable={{
                    onRowAdd: newData =>
                      new Promise((resolve, reject) =>
                        setTimeout(() => {
                          EventService.post(newData).then(() => {
                            this.updateEvents().then(resolve);
                          });
                        }, 1000)
                      ),
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) =>
                        setTimeout(() => {
                          EventService.put(newData).then(() => {
                            this.updateEvents().then(resolve);
                          });
                        }, 1000)
                      )
                  }}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }

  async updateEvents() {
    let events = await EventService.get();
    this.setState({data: events});
  }

  static formatDate(date) {
    const monthNames = [
      "January", "February", "March",
      "April", "May", "June", "July",
      "August", "September", "October",
      "November", "December"
    ];

    let dt = new Date(date);
    const day = dt.getDate();
    const monthIndex = dt.getMonth();
    const year = dt.getFullYear();
    return day + ' ' + monthNames[monthIndex] + ' ' + year;
  }
}

export const styles = {
  cardTitle: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};
