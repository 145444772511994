import React from "react";
import ReactLoading from 'react-loading';
import PropTypes from 'prop-types'

export class Loading extends React.Component {
  render() {
    return !this.props.hide ? (<></>) : (<div style={styles.loading}>
        <ReactLoading type={'bars'} height={100} width={50}/>
      </div>
    );
  }
}

Loading.propTypes = {
  hide: PropTypes.bool,
};

export const styles = {
  loading: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: '#00000080',
    zIndex: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
};
