import React, {Component} from "react";
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Card from "../../components/Card/Card.jsx";
import CardHeader from "../../components/Card/CardHeader.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import MaterialTable from "material-table";
import {Loading} from "../../components/Loading/Loading";
import {EventService} from "../../services/EventsService";
import {Box, Button} from "@material-ui/core";

export class Candidates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addon: null,
      columns: [
        {title: "Nome", field: "candidateAssessment.candidate.firstName"},
        {title: "Sobrenome", field: "candidateAssessment.candidate.lastName"},
        {title: "Documento", field: "candidateAddon.externalId"},
        {title: "E-mail", field: "candidateAssessment.candidate.email"},
        {title: "Celular", field: "candidateAssessment.candidate.phone"},
        {
          title: "Validador",
          field: "candidateAddon.externalValidation",
          render: rowData => (
            <span>
              {rowData.candidateAddon.externalValidation !== undefined
                ? rowData.candidateAddon.externalValidation
                : "--"}
            </span>
          )
        },
        {
          title: "Convite",
          field: "candidateAssessment.assessment.candidateInvitationUrl",
          render: rowData => (
            <a
              href={
                rowData.candidateAssessment.assessment.candidateInvitationUrl
              }
              target={"_blank"}
            >
              Abrir convite
            </a>
          )
        },
        {
          title: "Relatório",
          field: "candidateAssessment.assessment.reportsUrl",
          render: rowData => (
            <a
              href={rowData.candidateAssessment.assessment.reportsUrl}
              target={"_blank"}
            >
              Abrir relatório
            </a>
          )
        },
        {
          title: "Status",
          field: "candidateAssessment.assessment.status.name",
          render: rowData => (
            <span>
              {rowData.candidateAssessment.assessment.status.name === 'NotStarted'
                ? 'Not Started'
                : rowData.candidateAssessment.assessment.status.name}
            </span>
          )
        },

        {
          title: "Sumary",
          field: "candidateAssessment.assessment.resultSummary",
          render: rowData => (
            <span>
              {rowData.candidateAssessment.assessment.resultSummary
                ? rowData.candidateAssessment.assessment.resultSummary
                : "--"}
            </span>
          )
        },
        {
          title: "Resultado",
          field: "candidateAssessment.assessment.reportsPdfUrl",
          render: rowData => (
            <span>
              {rowData.candidateAssessment.assessment.reportsPdfUrl !==
              undefined ? (
                <a
                  href={rowData.candidateAssessment.assessment.reportsPdfUrl}
                  target={"_blank"}
                >
                  PDF
                </a>
              ) : (
                "--"
              )}
            </span>
          )
        },
        {
          title: "Cadastro",
          field: "candidateAssessment.candidate.register",
          type: "date",
          render: rowData => (
            <span>
              {this.formatDate(rowData.candidateAssessment.candidate.register)}
            </span>
          )
        }
      ],
      data: [],
      loading: true,
      btnGeneratePdf: true,
      btnSendPdf: true,
      selectedRow: null
    };
  }

  componentDidMount() {
    const {addonGuid} = this.props.match.params;
    this.updateAddon(addonGuid).then(() => {
      this.setState({loading: false});
    });
  }

  handleCheckButtons() {
    let list = this.state.data.filter(a => a.tableData.checked);
    if (list.length > 0) {
      this.setState({btnGeneratePdf: false});
      this.setState({btnSendPdf: false});
    } else {
      this.setState({btnGeneratePdf: true});
      this.setState({btnSendPdf: true});
    }
  }

  handleSelectChange(rows) {
    this.handleCheckButtons();
    this.setState({selectedRow: rows});
  }

  async handleGeneratePdf() {
    this.setState({loading: true});
    const {addonGuid} = this.props.match.params;

    let criteria = {
      addonGuid: addonGuid,
      candidateIdList: this.state.data
        .filter(a => a.tableData.checked)
        .map(b => b.candidateAssessment.candidate.candidateId)
    };

    try {
      await EventService.generatePdfs(criteria)
        .then(data => {
          alert("Pdfs gerados com sucesso!");
          window.location.reload();
        })
        .catch(err => {
          alert("Ocorreu um erro ao gerar os Pdfs, Por favor tente novamente!");
        });
    } catch (err) {
      console.log(err);
    }
    this.setState({loading: false});
  }

  async handleSendEmail() {
    this.setState({loading: true});
    const {addonGuid} = this.props.match.params;

    let criteria = {
      addonGuid: addonGuid,
      candidateIdList: this.state.data
        .filter(a => a.tableData.checked)
        .map(b => b.candidateAssessment.candidate.candidateId)
    };

    try {
      await EventService.sendPdfEmails(criteria)
        .then(data => {
          alert("Pdfs enviados com sucesso!");
          window.location.reload();
        })
        .catch(err => {
          alert(
            "Ocorreu um erro ao enviar os Pdfs, Por favor tente novamente!"
          );
        });
    } catch (err) {
      console.log(err);
    }
    this.setState({loading: false});
  }

  render() {
    const {addonGuid} = this.props.match.params;

    return (
      <GridContainer>
        <Loading hide={this.state.loading}/>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 style={styles.cardTitle}>
                {!this.state.addon ? "Event" : this.state.addon.name}
              </h4>
            </CardHeader>
            <CardBody>
              <Box>
                {/*                 <Button
                  variant="contained"
                  color="primary"
                  className={styles.buttonAction}
                  disabled={this.state.btnGeneratePdf}
                  onClick={() => {
                    if (
                      window.confirm(
                        "Você realmente deseja gerar pdfs para esses candidatos?"
                      )
                    ) {
                      this.handleGeneratePdf();
                    }
                  }}
                >
                  Gerar Pdf
                </Button>
                &nbsp;&nbsp;
                <Button
                  variant="contained"
                  color="primary"
                  className={styles.buttonAction}
                  disabled={this.state.btnSendPdf}
                  onClick={() => {
                    if (
                      window.confirm(
                        "Você realmente deseja enviar os pdfs para esses candidatos?"
                      )
                    ) {
                      this.handleSendEmail();
                    }
                  }}
                >
                  Enviar E-mail
                </Button> */}

                <Button
                  variant="contained"
                  color="primary"
                  className={styles.buttonAction}
                  target={"_blank"}
                  href={`https://thomas-integration-prod.azurewebsites.net/api/v1/event/candidate/${addonGuid}/csv`}
                >
                  Exportar para CSV
                </Button>

              </Box>
              <MaterialTable
                style={{boxShadow: "none"}}
                options={{
                  pageSize: 20,
                  sorting: true,
                  showTitle: false,
                  draggable: false,
                  actionsColumnIndex: 4,
                  showTextRowsSelected: false,
                  selection: false,
                  rowStyle: rowData => ({
                    backgroundColor: rowData.tableData.checked
                      ? "#E5E7E9"
                      : "#FFF"
                  })
                }}
                onSelectionChange={row => this.handleSelectChange(row)}
                columns={this.state.columns}
                data={this.state.data}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  async updateCandidates() {
  }

  async updateAddon(addonGuid) {
    let addon = await EventService.getAddonByGuid(addonGuid);
    let candidates = await EventService.getCandidatesByAddonId(addon.addonId);
    this.setState({data: candidates});
    this.setState({addon: addon});
  }

  formatDate(date) {
    let monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];

    let dt = new Date(date);
    const day = dt.getDate();
    const monthIndex = dt.getMonth();
    const year = dt.getFullYear();
    return day + " " + monthNames[monthIndex] + " " + year;
  }
}

export const styles = {
  cardTitle: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  buttonAction: {
    backgroundColor: "#ab47bc",
    marginRight: "10px",
    marginBottom: "10px"
  }
};
