import axios from 'axios';
import ServiceSetting from '../settings/ServiceSetting'
import {AccessStorage} from "../storages/AccessStorage";

export class UsersService {
  static get = async () => {
    try {
      let access = AccessStorage.get();
      let currentUser = AccessStorage.getUser();
      let resp = await axios.get(
        `${ServiceSetting.adminUrl}v1/admin-user/filter/type/${currentUser.type.guid}`,
        {headers: {"Authorization": `${access['token_type']} ${access['access_token']}`}}
      );
      return resp.data;
    } catch (e) {
      return null;
    }
  };

  static getByGuid = async (guid) => {
    try {
      let access = AccessStorage.get();
      let resp = await axios.get(
        `${ServiceSetting.adminUrl}v1/admin-user/${guid}`,
        {headers: {"Authorization": `${access['token_type']} ${access['access_token']}`}}
      );
      return resp.data;
    } catch (e) {
      return null;
    }
  };

  static getByUsernameAndPassword = async (username, password) => {
    try {
      let access = await AccessStorage.get();
      let resp = await axios.post(
        `${ServiceSetting.adminUrl}v1/admin-user/credentials`,
        {
          username: username,
          password: password
        },
        {headers: {"Authorization": `${access['token_type']} ${access['access_token']}`}}
      );
      return resp.data;
    } catch (e) {
      return null;
    }
  };

  static delete = async (adminUserId) => {
    try {
      let access = AccessStorage.get();
      let resp = await axios.delete(
        `${ServiceSetting.adminUrl}v1/admin-user/${adminUserId}`,
        {headers: {"Authorization": `${access['token_type']} ${access['access_token']}`}}
      );
      return resp.data;
    } catch (e) {
      return null;
    }
  };

  static post = async (user) => {
    try {
      let access = AccessStorage.get();
      let currentUser = AccessStorage.getUser();
      let resp = await axios.post(
        `${ServiceSetting.adminUrl}v1/admin-user/${currentUser.type.guid}`, user,
        {headers: {"Authorization": `${access['token_type']} ${access['access_token']}`}}
      );
      return resp.data;
    } catch (e) {
      return null;
    }
  };

  static put = async (user) => {
    try {
      let access = AccessStorage.get();
      let resp = await axios.put(
        ServiceSetting.adminUrl + 'v1/admin-user', user,
        {headers: {"Authorization": `${access['token_type']} ${access['access_token']}`}}
      );
      return resp.data;
    } catch (e) {
      return null;
    }
  };

}
